
import Vue, { VueConstructor } from "vue";
import FormMixins from "@/mixins/form";
import User, { DisplayUsers } from "@/models/user";
import { mapActions } from "vuex"; // mapState

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  mixins: [FormMixins],

  data() {
    return {
      client: undefined as DisplayUsers | undefined,
      organizations: [
        {
          name: "Agrinvest",
          value: "agrinvest",
        },
        {
          name: "Agroamazonia",
          value: "agroamazonia",
        },
      ],
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      saveUser: "SAVE_USER",
      updateToken: "UPDATE_TOKEN",
    }),
    initialize() {
      this.client = new DisplayUsers(new User(), 0, 0, true);
    },

    toUpdate(item: DisplayUsers): void {
      this.show = true;
      this.$nextTick(() => {
        this.client = Object.assign({}, item);
      });
    },

    save(): void {
      if (this.validate() && this.client) {
        let userToSave = new User(this.client);

        this.saveUser(userToSave)
          .then((response) => {
            this.$alertSuccess("Usuário atualizado com sucesso!");
            this.$emit("updateResourceList", response);
            this.updateToken();
            this.close();
          })
          .catch(() => {
            this.$alertError("Houve um problema ao atualizar o usuário");
          });
      }
    },
  },
});
