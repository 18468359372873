
import Vue, { VueConstructor } from "vue";

import UserForm from "./forms/UserForm.vue";
import CrudMixins from "@/mixins/crud";

import { mapGetters, mapActions } from "vuex"; // mapState
import { DisplayUsers } from "@/models/user";
import { IFormFuncs } from "@/types/form-types";
import { userService } from "@/services/user";


type Header = {
  text: string;
  value: string;
  align: string;
  sortable: boolean;
};

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  components: {
    UserForm,
  },
  mixins: [CrudMixins],
  data: () => ({
    users: [] as Array<DisplayUsers>,
    inset: false as boolean,
    searchString: "" as string,
    dialog: false as boolean,

    headers: [
      {
        text: "Nome",
        value: "name",
        align: "left",
        sortable: true,
      },
      {
        text: "Permissão",
        value: "roles",
        align: "left",
        sortable: true,
      },
      {
        text: "Email",
        value: "email",
        align: "left",
        sortable: true,
      },
      {
        text: "Cel",
        value: "phone_number",
        align: "left",
        sortable: true,
      },
      {
        text: "Organização",
        value: "organization",
        align: "left",
        sortable: true,
      },
      {
        text: "Fazendas",
        value: "ownerships",
        align: "left",
        sortable: true,
      },
      {
        text: "Gatilhos",
        value: "sale_triggers",
        align: "left",
        sortable: true,
      },
      {
        text: "Envio email",
        value: "send_email",
        align: "left",
        sortable: true,
      },
      {
        text: "Envio whats",
        value: "send_whats",
        align: "left",
        sortable: true,
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ] as Array<Header>,
  }),

  async mounted() {
    this.localLoading = true;
    let response = await userService.getNusers();
    this.users = response;
    this.localLoading = false;
  },

  methods: {
    ...mapActions({
      updateClient: "UPDATE_CLIENT",
    }),

    editUser(item: DisplayUsers): void {
      const userForm = this.$refs["userForm"] as IFormFuncs<DisplayUsers>;
      if (userForm && userForm.toUpdate) userForm.toUpdate(item);
    },

    pickUser(item: DisplayUsers) {
      this.updateClient(item);

      const isN1 = item.userRole === "N1";
      this.$router.push({ name: "Profit", params: { adminAsN1: isN1.toString() } });
    },
  },
  computed: {
    ...mapGetters({
      client: "getClient",
    }),

    filteredList(): DisplayUsers[] {
      return this.users.map((user) => {
        let select = this.client && this.client.id === user.id ? true : false;
        return new DisplayUsers(
          user,
          user.ownerships,
          user.sale_triggers,
          select
        );
      });
    },
    totalUsers(): number {
      return this.filteredList.length;
    },
  },
});
